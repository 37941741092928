import React, { useEffect, useState } from "react";
import "./Table.scss";
import { Box } from "@mui/material";
import { json, useNavigate } from "react-router-dom";
import Button from "../../Comon/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  assignPractitioner,
  downloadPdf,
  updatePatientsSort,
  updateSurveyNotes,
} from "../../../../core/axios/services";
import { saveAs } from "file-saver";
import i18n from "i18next";
import { resetPopUp, showPopup } from "../../../../redux/actions/app";
import { current } from "@reduxjs/toolkit";
import { addUser } from "../../../../redux/actions/user";
import Dropdown from "../Dropdown/Dropdown";
import GetSurveyLinkForLanguageAndTypeModal from "../../GetSurveyLinkForLanguageAndTypeModal";
import {Tooltip as ReactTooltip} from "react-tooltip";
import SetSurveyDefaultsForPracticeHubPatientModal from "../../SetSurveyDefaultsForPracticeHubPatientModal";
import Loader from "../../Loader/Loader";

const Table = ({
  app,
  i18n,
  data,
  type,
  page,
  setPage,
  total,
  sort,
  setSort,
  showAll,
  setShowAll,
  sortDirection,
  setSortDirection,
  perPage,
  setData,
  setPatients,
  showGlobalSnackbar,
  copyLink,
  sendEmail,
  generateLink,
  deleteSurvey,
  practitioners,
  selectedData,
  setSelectedData,
                 patient,
                 isFetchingPatientSurveyData,
                 setLink,
                 rangeString,
                 resetPageToStart,
    isFetchingData = true,
    link
}) => {

  function hasOneSurvey(){
    return data.find((item) => item.existing)
  }

  const [pages, setPages] = useState([0]);
  const [sortName, setSortName] = useState("");
  const [moreName, setMoreName] = useState("");
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState("");

  useEffect(() => {
    if(currentPage == 0){
      setCurrentPage(1)
    }
  }, [currentPage]);

  const navigate = useNavigate();

  const getTypeString = (item) => {
    switch (item.type) {
      case 1:
        return "ChiroForm";
      case 2:
        return "ChiroForm +";
      case 3:
        return "ChiroForm (CP)";
    }
  };

  useEffect(() => {
    if (type === "patients") {
      setSortName(i18n.t("patientName"));
      setMoreName(i18n.t("seePatientProfile"));
    } else if (type === "users") {
      setSortName(i18n.t("displayName"));
      setMoreName(i18n.t("seeMore"));
    } else if (type === "practitioners") {
      setSortName(i18n.t("practitionerName"));
      setMoreName(i18n.t("seeMore"));
    }

    const lastPage = Math.ceil(total / perPage);

    if (lastPage > 0) {
      const pages = Array.from(Array(lastPage).keys());
      if (pages.length <= 5) {
        setPages(pages);
      } else {
        let pagesFirstPart = [
          pages[currentPage - 1],
          pages[currentPage],
          pages[currentPage + 1],
          "...",
        ];

        if (currentPage >= pages[pages.length - 3]) {
          setCurrentPage(currentPage);
          pagesFirstPart = [
            pages[currentPage - 2],
            pages[currentPage - 1],
            pages[currentPage],
            "...",
          ];
        }

        const pagesItems = [
          ...pagesFirstPart,
          ...[
              pages[pages.length - 2],
              pages[pages.length - 1]
          ]
        ];

        setPages(pagesItems);
      }
    }
  }, [type, data, page, total, perPage, currentPage]);

  useEffect(() => {
    if (app.popupStatus === true) {
      switch (app.popUpData?.type) {
        case "surveyNotes":
          updateSurveyNotes(
            { notes: app.popUpConfirmData, id: app.popUpData.specifics },
            user
          );
          updateItemNotes(app.popUpConfirmData, app.popUpData.specifics);
          dispatch(resetPopUp());
          break;
      }
    } else if (app.popupStatus === false) {
      dispatch(resetPopUp());
    }
  }, [app]);

  const updateItemNotes = (notes, id) => {
    const index = data.findIndex((item) => item.id === id);

    if (index !== -1) {
      data[index].notes = notes;
      setData([...data]);
    }
  };

  const toggleSort = async (sortType) => {
    if(resetPageToStart){
      resetPageToStart();
    }
    setSort(sortType);
    user.user.patientSort = sortType;
    if (sortDirection === "asc") {
      setSortDirection("desc");
      updatePatientsSort(sortType, "desc", user);
      user.user.patientSortDir = "desc";
    } else {
      setSortDirection("asc");
      updatePatientsSort(sortType, "asc", user);
      user.user.patientSortDir = "asc";
    }
    dispatch(addUser(user.user));
  };

  const goToPage = (selectedPage) => {
    if (typeof selectedPage === "number") {
      if (selectedPage < pages[0] || selectedPage > pages[pages.length - 1]) {
        return;
      }
      setPage(selectedPage);

      if (selectedPage >= pages[pages.length - 2]) {
        // setCurrentPage(1);
      } else if (selectedPage == 0) {
        setCurrentPage(1);
      } else {
        setCurrentPage(selectedPage);
      }
    }
  };

  const clickMore = (item) => {
    if (type === "patients") {
      navigate("/dashboard/patient/" + item.id);
    } else if (type === "users") {
      navigate("/dashboard/user/" + item.id);
    } else if (type === "practitioners") {
      navigate("/dashboard/practitioner/" + item.id);
    }
  };

  const getTypeValues = (type) => {
    let obj = { name: "", class: "dataCell typeCell" };
    switch (type) {
      case 2:
        obj.name = i18n.t("admin");
        obj.class += " adminTypeCell";
        break;
      case 3:
        obj.name = i18n.t("user");
        obj.class += " userTypeCell";
        break;
      default:
        obj.name = i18n.t("user");
        obj.class += " userTypeCell";
        break;
    }

    return obj;
  };

  const handleGenerated = (index) => {
    if (data[index - 1]?.createdAt) {
      data[index].generated = true;
      generateLink(index);
      setData([...data]);
    }
  };

  const addNotes = (item) => {
    const data = {
      showPopup: true,
      data: {
        title: i18n.t("surveyNotesTitle"),
        confirmButton: i18n.t("confirmSurveyNotes"),
        denyButton: i18n.t("denySurveyNotes"),
        type: "surveyNotes",
        data: item.notes ? item.notes : "",
        specifics: item.id,
      },
    };
    dispatch(showPopup(data));
  };

  const generatePdf = (id) => {
    showGlobalSnackbar(i18n.t("loading"), 5000);
    try {
      downloadPdf(id, user).then((res) => {
        let filename = res.headers.get("filename");
        filename = decodeURIComponent(escape(filename));
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      });
    } catch (e) {
      showGlobalSnackbar(i18n.t("thereWasAnError"));
    }
  };

  const seeMore = (item, index) => {
    let url = "";
    if (item.surveyOrder === 1) {
      url =
        process.env.REACT_APP_API_WEBVIEW +
        "/survey-edit/" +
        item.code +
        "/" +
        item.id;
    } else {
      url =
        process.env.REACT_APP_API_WEBVIEW +
        "/survey-next-edit/" +
        item.code +
        "/" +
        item.id +
        "/" +
        data[index - 1].id;
    }

    const win = window.open(url, "_blank");
    win.focus();
  };

  const selectPractitioner = (patient, practitioner) => {
    patient.practitioner = practitioner;
    const index = data.findIndex((p) => p.id == practitioner.id);
    if (index != -1) {
      data[index] = patient;
    }
    setPatients(data);
    const postData = {
      patientId: patient.id,
      practitionerId: practitioner.id,
    };

    try {
      assignPractitioner(user, postData).then(() => {
        showGlobalSnackbar(i18n.t("saved"));
      });
    } catch (error) {
      showGlobalSnackbar(i18n.t("thereWasAnError"));
    }
  };

  const toggleSelected = (id) => {
    const index = selectedData.findIndex((s) => s == id);

    if (index == -1) {
      selectedData.push(id);
    } else {
      selectedData.splice(index, 1);
    }

    setSelectedData(selectedData);
  };

  return (
    <div className="Table">
      <div className="tableScroll">
            <table className={"dataTable"}>
              <thead>
              <tr className={"headRow"}>
                {type === "patients" ||
                type === "users" ||
                type === "practitioners" ? (
                    <th
                        className={"dataHead nameHead"}
                        onClick={() => toggleSort("name")}
                    >
                      {sort === "name" ? (
                          <img
                              className={"sortIcon"}
                              alt="sortIcon"
                              src={"/assets/Icon_Sorting-Arrows.svg"}
                              width="14px"
                              height="16px"
                          />
                      ) : (
                          <img
                              className={"sortIcon"}
                              alt="sortIcon"
                              src={"/assets/Icon_Sorting-ArrowsDisable.svg"}
                              width="14px"
                              height="16px"
                          />
                      )}

                      {sortName}
                    </th>
                ) : null}

                {type == "practitioners" ? (
                    <th className={"dataHead"} style={{paddingLeft: "110px"}}>
                      {i18n.t("patientsNumber")}
                    </th>
                ) : null}

                {type == "practitioners" ? (
                    <th
                        className={"dataHead lastCellPatients"}
                        style={{paddingLeft: "110px"}}
                    ></th>
                ) : null}

                {type === "patients" ? (
                    <th className={"dataHead"} style={{paddingLeft: "110px"}}>
                      {i18n.t("practitioner")}
                    </th>
                ) : null}

                {type === "patients" && (
                    <th
                        className={"dataHead"}
                        style={{paddingLeft: "110px"}}
                        onClick={() => toggleSort("date")}
                    >
                      {sort === "date" ? (
                          <img
                              className={"sortIcon"}
                              alt="sortIcon"
                              src={"/assets/Icon_Sorting-Arrows.svg"}
                              width="14px"
                              height="16px"
                          />
                      ) : (
                          <img
                              className={"sortIcon"}
                              alt="sortIcon"
                              src={"/assets/Icon_Sorting-ArrowsDisable.svg"}
                              width="14px"
                              height="16px"
                          />
                      )}

                      {i18n.t("SurveyDateSort")}
                    </th>
                )}

                {type === "patients" && (
                    <th className={"dataHead"} style={{opacity: 0}}>
                      <img
                          className={"sortIcon"}
                          alt="sortIcon"
                          src={"/assets/Icon_Sorting-Arrows.svg"}
                          width="14px"
                          height="16px"
                      />{" "}
                      {i18n.t("SurveyDateSort")}
                    </th>
                )}

                {type === "users" ? (
                    <th className={"dataHead roleHead"}>{i18n.t("role")}</th>
                ) : null}
                {type === "users" && user?.user?.type === 2 ? (
                    <th
                        className={"dataHead buttonHead"}
                        onClick={() => navigate("/dashboard/user/0")}
                    >
                      {i18n.t("createNewUser")}
                    </th>
                ) : null}
                {type === "surveys" ? (
                    <th className={"dataHead surveyCell"}>{i18n.t("survey")}</th>
                ) : null}
                {type === "surveys" ? (
                    <th className={"dataHead"}>{i18n.t("date")}</th>
                ) : null}
                {type === "surveys" ? (
                    <th className={"dataHead lastCellSurveys"}></th>
                ) : null}
              </tr>
              </thead>
              {
                isFetchingData ?
                    <tbody className="patientsTable" style={{width:'100%', height:'100%', minHeight:'410px', display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <Loader/>
                    </tbody>
                    :
                    <tbody className="patientsTable">
              {data
                  ? data.map((item, index) => {
                    return (
                        <tr className={"dataRow"} key={index}>
                          {type === "patients" ? (
                              <span>
                        <td className={"selectCell"}>
                          {" "}
                          <input
                              type="checkbox"
                              onChange={() => toggleSelected(item.id)}
                          />
                        </td>
                        <td className={"dataCell nameCell"}>
                          <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            color:  item?.active ? 'inherit' : 'gray',
                          }}>
                            {item.name} {item.lastName}{" "}
                            {item?.practiceHubPatientId &&
                                <div id={`tooltip_${item.id}`} style={{cursor: 'pointer'}}>
                                  <img src={
                                    item?.active ?
                                    "/assets/ic_ph logo@3x.png"
                                        :
                                        "/assets/ic_ph logo_gray@3x.png"
                                  } alt="practicehub" style={{
                                    width: '15px',
                                    height: '15px',
                                    objectFit: 'contain',
                                    marginLeft: '5px'
                                  }}/>
                                  <ReactTooltip
                                      anchorId={`tooltip_${item.id}`}
                                      place="right"
                                      content={`PracticeHub ID: ${item?.practiceHubPatientId}`}
                                  />
                                </div>
                            }
                          </div>
                        </td>
                      </span>
                          ) : null}
                          {type === "practitioners" ? (
                              <td className={"dataCell nameCell"}>
                                {" "}
                                {item.firstName} {item.lastName}{" "}
                              </td>
                          ) : null}
                          {type === "users" ? (
                              <td className={"dataCell nameCell"}> {item.name} </td>
                          ) : null}
                          {type === "users" ? (
                              <td className={getTypeValues(item.type).class}>
                                {" "}
                                {getTypeValues(item.type).name}{" "}
                              </td>
                          ) : null}
                          {type === "patients" && (
                              <td className={"selectPractitioner dataCell"}>
                                <Dropdown
                                    options={practitioners}
                                    setSelectedOption={(selected) =>
                                        selectPractitioner(item, selected)
                                    }
                                    selectedOption={item.practitioner}
                                    label={i18n.t("selectPractitioner")}
                                    type={"practitioner"}
                                    placeholder={i18n.t("noPractitioner")}
                                />
                              </td>
                          )}

                          {type === "patients" && (
                              <td className="surveyDatePatient">
                                {item.lastSurveyDate}
                              </td>
                          )}
                          {type === "practitioners" && (
                              <td>{item.patientsCount}</td>
                          )}

                          {type === "patients" ? (
                              <td
                                  className={"dataCell lastCellPatients"}
                                  onClick={() => clickMore(item)}
                              >
                                {" "}
                                {moreName}{" "}
                                <img
                                    className={"rightArrowIcon"}
                                    alt="rightArrowIcon"
                                    src={"/assets/Icon_Arrow-Right.svg"}
                                    width="9px"
                                    height="100%"
                                />
                              </td>
                          ) : null}
                          {type === "users" || type == "practitioners" ? (
                              <td
                                  className={"dataCell lastCellUsers"}
                                  onClick={() => clickMore(item)}
                              >
                                <div className={"lastCellUsersContent"}>
                                  {moreName}
                                  <img
                                      className={"rightArrowIcon"}
                                      alt="rightArrowIcon"
                                      src={"/assets/Icon_Arrow-Right.svg"}
                                      width="9px"
                                      height="100%"
                                  />
                                </div>
                              </td>
                          ) : null}

                          {type === "surveys" ? (
                              <td className={"dataCell surveyCell"}>
                                {" "}
                                {i18n.t("survey")} {item.surveyOrder}{" "}
                                {getTypeString(item)}{" "}
                              </td>
                          ) : null}

                          {type === "surveys" ? (
                              <td className={"dataCell"}> {item.createdAt} </td>
                          ) : null}

                          {type === "surveys" ? (
                              <td className={"dataCell lastCellSurveys"}>


                                {index !== 0 && item.existing === false &&
                                item.generated === false ? (
                                    <div
                                        className={"generateSurveyContainer"}
                                        onClick={() => handleGenerated(index)}
                                    >
                                      <Button
                                          text={i18n.t("generateSurvey")}
                                          disabled={!data[index - 1]?.createdAt}
                                          mode={"small"}
                                      />
                                    </div>
                                ) : null}

                                {item.existing === true ? (
                                    <div className={"smallButtonsCell"}>
                                      {index ===
                                      data.filter((s) => s.existing === true).length -
                                      1 && index > 0 ? (
                                          <div
                                              className={"smallButtonContainers"}
                                              onClick={() => deleteSurvey(item.id)}
                                          >
                                            <Button
                                                text={i18n.t("delete")}
                                                mode={"small"}
                                                color={"white"}
                                                border={"red"}
                                            />
                                          </div>
                                      ) : null}
                                      <div
                                          className={"smallButtonContainers"}
                                          onClick={() => addNotes(item)}
                                      >
                                        <Button
                                            text={
                                              item.notes === null
                                                  ? i18n.t("addNotes")
                                                  : i18n.t("viewNotes")
                                            }
                                            mode={"small"}
                                            color={"white"}
                                        />
                                      </div>

                                      <div
                                          className={"smallButtonContainers"}
                                          onClick={() => generatePdf(item.id)}
                                      >
                                        <Button
                                            text={i18n.t("generatePdf")}
                                            mode={"small"}
                                        />
                                      </div>
                                      <div
                                          className="viewResultsCell"
                                          onClick={() => seeMore(item, index)}
                                      >
                                        {i18n.t("viewResults")}{" "}
                                        <img
                                            className={"rightArrowIcon"}
                                            alt="rightArrowIcon"
                                            src={"/assets/Icon_Arrow-Right.svg"}
                                            width="9px"
                                            height="100%"
                                        />
                                      </div>
                                    </div>
                                ) : null}

                                {item.existing === false &&
                                item.generated === true ? (
                                    <div className={"smallButtonsCell"}>
                                      {
                                          hasOneSurvey() && <div
                                              className={"smallButtonContainers"}
                                              onClick={() => sendEmail(index)}
                                          >
                                            <Button
                                                text={i18n.t("sendToEmail")}
                                                mode={"small"}
                                                color={"white"}
                                            />
                                          </div>
                                      }

                                      <div
                                          className={"smallButtonContainers"}
                                          onClick={() => copyLink(index)}
                                      >
                                        <Button
                                            text={i18n.t("copyLink")}
                                            mode={"small"}
                                        />
                                      </div>
                                    </div>
                                ) : null}


                                {
                                    (!hasOneSurvey() && index === 0 && !link) &&
                                    (
                                        patient?.practiceHubPatientId ?
                                            <SetSurveyDefaultsForPracticeHubPatientModal
                                                disabled={isFetchingPatientSurveyData}
                                                patient={patient}
                                                showGlobalSnackbar={showGlobalSnackbar}
                                                setLink={setLink}
                                                data={data}
                                                setData={setData}
                                            />
                                            :
                                            <GetSurveyLinkForLanguageAndTypeModal
                                                disabled={isFetchingPatientSurveyData}
                                                patient={patient}
                                                showGlobalSnackbar={showGlobalSnackbar}
                                                setLink={setLink}
                                                data={data}
                                                setData={setData}
                                            />
                                    )
                                }

                              </td>
                          ) : null}
                        </tr>
                    );
                  })
                  : null}
              </tbody>
              }
            </table>
      </div>

      {type !== "surveys" ? (
          <div className={"tableFooter"}>
            <div className={"tableFooterLeft"}>
              <div className={"resultsInfo"}>
                {rangeString ?? i18n.t("showingOutOf", {count: data?.length, total})}
              </div>
              {!showAll ? (
                  <div
                      className={"viewAllResults"}
                      onClick={() => setShowAll(true)}
                  >
                    {i18n.t("viewAllResults")}
                  </div>
              ) : null}
            </div>
            {
                !showAll && <div className={"tableFooterRight"}>
                  <img
                      className={"iconLeftPaginate"}
                      src={"/assets/Arrow_Left.svg"}
                      alt="iconLeftPaginate"
                      onClick={() => goToPage(page - 1)}
                      width="7px"
                      height="100%"
                  />
                  {pages.map((pageOption, index) => {
                    let pageClass = "page";
                    if (pageOption === page) {
                      pageClass += " activePage";
                    }
                    let pageNumber = pageOption;
                    if (typeof pageOption === "number") {
                      pageNumber += 1;
                    }
                    return (
                        <div
                            style={{minWidth: '20px'}}
                            className={pageClass}
                            onClick={() => goToPage(pageOption)}
                            key={index}
                        >
                          {" "}
                          {pageNumber}{" "}
                        </div>
                    );
                  })}
                  <img
                      className={"iconRightPaginate"}
                      onClick={() => goToPage(page + 1)}
                      src={"/assets/Arrow_Right.svg"}
                      alt="iconRightPaginate"
                      width="8px"
                      height="100%"
                  />
                </div>
            }

          </div>
      ) : null}
    </div>
  );
};

export default Table;
