import React, { useState } from "react";

export const TestimonialCard = ({ item, onClick }) => {

  const truncateText = (text, length) => {
    if (text.length <= length) return text;
    return text.slice(0, length) + "...";
  };

  return (
    <div className="testimonialCardWrapper">
      <div className="topCard">
        <img src={item.image} alt={item.name} />
        <div className="topCardName">
          <h3>{item.name}</h3>
          <h5>{item.title}</h5>
        </div>
      </div>
      <p>{truncateText(item.text, 360)}</p>
      {item.text.length > 360 && (
        <button className="readMoreBtn" onClick={onClick}>
          Read More
        </button>
      )}
    </div>
  );
};
