import React, { useState, useEffect } from "react";
import "./NewLanding.scss";
import { useSelector } from "react-redux";
import { TestimonialCard } from "../../components/TestimonialCard";

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsPerRow, setItemsPerRow] = useState(3);
  const gap = 20;
  const [open, setOpen] = useState(false);
  const [openedItem, setOpenedItem] = useState("");

  const app = useSelector((state) => state.app);

  const testimonials = [
    {
      id: 1,
      image: "/assets/jeremy.jpg",
      name: "Jeremy McMinn",
      title: "Founder of PracticeHub",
      text: "When I was first demoed Chiropractic Outcomes by Brock, I was blown away by the simplicity and power of what it does - showing patients visually and simply how far they've improved with Chiropractic and the ongoing benefit of continuing their care.  By adding this to your business toolkit, you'll see far better retention and understanding of how vital Chiropractic is by your patients.  We're very excited about the numerous retention benefits it will bring practice owners, and for our customers and look forward to integrating the platform into PracticeHub to bring it to our customer base. ",
    },

    {
      id: 2,
      image: "/assets/marcelo.jpeg",
      name: "Marcelo Ruiz",
      title: "Chiropractor",
      text: "Since we began using Chiroform in our office, we have seen an incredible transformation in the way we manage, evaluate and communicate the progress of our practice members. This program not only provides us with accurate metrics and statistics, but also allows us to visualize the evolution of each patient in a clear and understandable way. The comparisons that Chiroform offers are especially valuable, as they help patients realize the aspects that they are improving since they began their chiropractic care, which reinforces their commitment to treatment. As an education and loyalty tool, it is fabulous. The interface is intuitive and easy to use, which has facilitated the training of our team. In addition, Brock, the creator of the program, has helped us from the beginning and continues to give us all the support we need.  Without a doubt, Chiroform has become an indispensable tool in our daily lives. I recommend this program to any chiropractic office looking to optimize their practice and offer exceptional service",
    },
    {
      id: 3,
      image: "/assets/naomi.jpg",
      name: "Naomi Jagiello",
      title: "Chiropractor",
      text: "This app takes the pain out of revisions/progress exams! It’s made transitioning people into wellness care so much easier, they can clearly see the impact chiropractic care is having on their quality of life, not only a reduction in their symptoms.",
    },
    {
      id: 4,
      image: "/assets/nimrod.jpeg",
      name: "Nimrod Mueller",
      title: "Chiropractor",
      text: "I cannot speak highly enough about Chiropractic Outcomes. It’s the most efficient way I’ve seen to have our practice people report on their own experiences of change and improvement with chiropractic, helping them see the great benefits to choosing to continue care. It neatly captures the right information to empower exploring and educating what’s truly important to them. It makes our progress exams laser-focussed, and more efficient. Plus, it looks sexy!",
    },
    {
      id: 5,
      image: "/assets/vismai.jpg",
      name: "Vismai Schonfelder",
      title: "Chiropractor",
      text: "To set up and activate only took me a few minutes; our patients find it simple to complete - and we value the data, referral acquisitions, and increased client retention from Chiropractic Outcomes. A modern, client centric tool worth having in your chiropractic office.",
    },
    {
      id: 6,
      image: "/assets/marc.png",
      name: "Marc Hudson",
      title: "Chiropractor",
      text: "Chiropractic Outcomes is like your progress exams on steroids",
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setItemsPerRow(1);
      } else if (window.innerWidth <= 992) {
        setItemsPerRow(2);
      } else {
        setItemsPerRow(3);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

 const handleNext = () => {
   setCurrentIndex((prevIndex) =>
     prevIndex < testimonials.length - itemsPerRow ? prevIndex + 1 : 0
   );
 };

 const handlePrev = () => {
   setCurrentIndex((prevIndex) =>
     prevIndex > 0 ? prevIndex - 1 : testimonials.length - itemsPerRow
   );
 };


  const handleReadMore = (item) => {
    setOpen(!open);
    setOpenedItem(item);
  };

  return (
    <div className="testimonialWrap testimonialContainerWrapper">
      <div className="topContent">
        <p className="landing-thin-font p-0">
          {app?.app_data?.landingData.testimonial_title_regular1}
          <span className="landing-section-seven-span">
            {" "}
            {app?.app_data?.landingData.testimonial_title_bold}
          </span>{" "}
          {app?.app_data?.landingData.testimonial_title_regular2}
        </p>
      </div>

      <div className="testimonialsSlide">
        <div className="testimonialsContainerWrapper">
          <div
            className="testimonialsContainer"
            style={{
              transform: `translateX(-${
                currentIndex * (100 / itemsPerRow + gap / window.innerWidth)
              }%)`,
              gap: `${gap}px`,
            }}
          >
            {testimonials.map((item, index) => (
              <TestimonialCard
                key={index}
                item={item}
                onClick={() => handleReadMore(item)}
              />
            ))}
          </div>
        </div>

        <div className="slideBtns">
          <img
            src="/assets/testimonialLeftArrow.svg"
            alt="Previous"
            onClick={handlePrev}
          />
          <img
            src="/assets/testimonialRightArrow.svg"
            alt="Next"
            onClick={handleNext}
          />
        </div>
      </div>

      {open && (
        <div className="testimonialOverlay">
          <div className="testimonialModelContent">
            <img className="closeIcon" src="/assets/closeIcon.svg" onClick={() => setOpen(!open)} />
            <img className="profile" src={openedItem.image} />
            <h3>{openedItem.name}</h3>
            <h5>{openedItem.title}</h5>
            <p>{openedItem.text}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Testimonials;
