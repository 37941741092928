import React, { useState, useMemo } from "react";
import "./NewLanding.scss";
import { useSelector } from "react-redux";
import getThemes from "../../../constants/colors/getTheme";
import AllVideos from "../../components/Survey/VideoPlayer/AllVideos";

const Benefits = () => {
  const [openedPanel, setOpenedPanel] = useState(0);
  const app = useSelector((state) => state.app);
   const themeColor = useMemo(() => {
     return getThemes("mainColor", app.appTheme);
   }, [app.appTheme]);
   const [videoSize, setVideoSize] = useState("");

  const scroll = () => {
    window.open("https://calendly.com/chiropractic_outcomes/30min", "_blank");
  };

  const togglePanel = (item) => {
    setOpenedPanel(openedPanel === item ? 0 : item);
  };

  return (
    <div className="benefitsWrap benefitsContainerWrapper">
      <div className="benefitsWidth">
        <div className="topContent">
          <p className="m-0">
            <span className="landing-thin-font landing-section-three-top-letters">
              <span className="landing-benefits">
                {app?.app_data?.landingData.laptop_title_bold}{" "}
              </span>{" "}
              {app?.app_data?.landingData.laptop_title_regular}
            </span>
          </p>
          <p className="m-0">{app?.app_data?.landingData.laptop_text}</p>
          <button
            className="btn bg-primary landing-bolder-font landing-section-three-button"
            onClick={scroll}
          >
            {app?.app_data?.landingData.cta}
          </button>
        </div>
        <div className="dropdownListContent">
          <div>
            {[1, 2, 3, 4].map((number) => (
              <div
                className="landing-panel"
                key={number}
                onClick={() => togglePanel(number)}
              >
                <div className="landing-panel-head accordion-button">
                  <p className="landing-panel-text landing-panel-text-head landing-panel-no-body">
                    {app?.app_data?.landingData?.panel_titles[number - 1]}
                  </p>
                  <img
                    src={"/assets/landing/arrow-down.svg"}
                    alt="arrow down"
                    className={`fas fa-angle-down ${
                      openedPanel === number ? "rotated-arrow" : ""
                    }`}
                  />
                </div>
                <div
                  className={`landing-panel-body collapse ${
                    openedPanel === number ? "show" : ""
                  }`}
                >
                  <p className="landing-panel-body-text">
                    {app?.app_data?.landingData?.panel_texts[number - 1]}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div>
            {[5, 6, 7, 8].map((number) => (
              <div
                className="landing-panel"
                key={number}
                onClick={() => togglePanel(number)}
              >
                <div className="landing-panel-head accordion-button">
                  <p className="landing-panel-text landing-panel-text-head landing-panel-no-body">
                    {app?.app_data?.landingData?.panel_titles[number - 1]}
                  </p>
                  <img
                    src={"/assets/landing/arrow-down.svg"}
                    alt="arrow down"
                    className={`fas fa-angle-down ${
                      openedPanel === number ? "rotated-arrow" : ""
                    }`}
                  />
                </div>
                <div
                  className={`landing-panel-body collapse ${
                    openedPanel === number ? "show" : ""
                  }`}
                >
                  <p className="landing-panel-body-text">
                    {app?.app_data?.landingData?.panel_texts[number - 1]}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="videoStyle">
          <AllVideos
            themeColor={themeColor}
            url={"https://vimeo.com/1031502772?share=copy"}
            videoSize={videoSize}
            landing={false}
            className="videoClip"
            volume={100}
          />
        </div>
      </div>
    </div>
  );
};

export default Benefits;
