import React, { useState, useMemo } from "react";
import "./NewLanding.scss";
import { useDispatch } from "react-redux";
import AllVideos from "../../components/Survey/VideoPlayer/AllVideos";
import getThemes from "../../../constants/colors/getTheme";
import { useSelector } from "react-redux";

const Video = () => {
  const app = useSelector((state) => state.app);
  const themeColor = useMemo(() => {
    return getThemes("mainColor", app.appTheme);
  }, [app.appTheme]);
  const [videoSize, setVideoSize] = useState("");

  console.log(
    app?.app_data?.landingData.video,
    "app?.app_data?.landingData.video"
  );

  return (
    <div className="videoWrap videoContainerWrapp">
      <div className="topContent">
        <p className="landing-thin-font">
          {app?.app_data?.landingData.video_title}
        </p>
      </div>
      <div className="bottomContent">
        <AllVideos
          themeColor={themeColor}
          url={app?.app_data?.landingData.video}
          videoSize={videoSize}
          landing={false}
          className="videoClip"
          volume={100}
        />
      </div>
    </div>
  );
};

export default Video;
